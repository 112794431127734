var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.goBack();
      }
    }
  }, [_c('v-icon', [_vm._v("arrow_back")])], 1), _c('v-toolbar-title', [_vm._v("流程设置")]), _c('v-spacer')], 1), _c('v-card-subtitle', [_vm._v(" 配置不同业务群组的结算执行环节执行人，重新配置后不会修改原有流程，仅对新的流程启用。 ")]), _c('v-data-table', {
    attrs: {
      "headers": _vm.headerTable,
      "items": _vm.taskList,
      "options": _vm.optionsTable,
      "server-items-length": _vm.taskCount,
      "loading": _vm.taskLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [10, 20, 50, 100]
      },
      "item-key": "_id",
      "transition": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsTable = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-tabs', {
          attrs: {
            "icons-and-text": "",
            "fixed-tabs": ""
          },
          model: {
            value: _vm.active_tab,
            callback: function callback($$v) {
              _vm.active_tab = $$v;
            },
            expression: "active_tab"
          }
        }, _vm._l(_vm.businessGroup, function (item) {
          return _c('v-tab', {
            key: item.code,
            on: {
              "click": function click($event) {
                return _vm.changeTab(item.code);
              }
            }
          }, [_vm._v(_vm._s(item.name)), _c('v-icon', {
            domProps: {
              "textContent": _vm._s(item.icon)
            }
          })], 1);
        }), 1), _c('v-spacer'), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-btn', {
          attrs: {
            "depressed": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.createItem();
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("add")]), _vm._v("新建任务")], 1), _c('v-divider', {
          staticClass: "mx-4",
          attrs: {
            "inset": "",
            "vertical": ""
          }
        }), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref) {
              var on = _ref.on,
                attrs = _ref.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "icon": ""
                },
                on: {
                  "click": function click($event) {
                    $event.stopPropagation();
                    return _vm.openFilter.apply(null, arguments);
                  }
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("filter_list")])], 1)];
            }
          }])
        }, [_c('span', [_vm._v("过滤数据")])])], 1)];
      },
      proxy: true
    }, {
      key: "no-data",
      fn: function fn() {
        return [_c('v-btn', {
          attrs: {
            "text": ""
          },
          on: {
            "click": function click($event) {
              return _vm.getDataList();
            }
          }
        }, [_vm._v("重新获取数据")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn(props) {
        return [_c('draggable', _vm._b({
          attrs: {
            "tag": "tbody"
          },
          model: {
            value: _vm.orderList,
            callback: function callback($$v) {
              _vm.orderList = $$v;
            },
            expression: "orderList"
          }
        }, 'draggable', _vm.dragOptions, false), _vm._l(props.items, function (item, index) {
          var _item$operator, _item$operator$person;
          return _c('tr', {
            key: index,
            class: item.isEnable ? '' : _vm.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
          }, [_c('td', [_c('v-icon', {
            staticClass: "mt-2",
            staticStyle: {
              "cursor": "move"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("drag_indicator")])], 1), _c('td', [_vm._v(" " + _vm._s(item.name) + " "), _vm.taskList.indexOf(item) === 0 ? _c('v-icon', {
            staticClass: "ma-2",
            attrs: {
              "color": "info"
            }
          }, [_vm._v("outlined_flag")]) : _vm._e(), _vm.taskList.indexOf(item) === _vm.taskList.length - 1 ? _c('v-icon', {
            staticClass: "ma-2",
            attrs: {
              "color": "info"
            }
          }, [_vm._v("flag")]) : _vm._e()], 1), _c('td', [item.operator ? _c('account-dialogs', {
            attrs: {
              "account": item.operator._id
            }
          }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$operator = item.operator) === null || _item$operator === void 0 ? void 0 : (_item$operator$person = _item$operator.personal) === null || _item$operator$person === void 0 ? void 0 : _item$operator$person.name) || '*未设置姓名') + " ")]) : _vm._e()], 1), _c('td', {
            staticClass: "text-center"
          }, [_c('v-icon', {
            staticClass: "mr-2",
            attrs: {
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.createItem(item);
              }
            }
          }, [_vm._v("edit")]), !item.isEnable ? _c('v-icon', {
            attrs: {
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.activeItem(item, true);
              }
            }
          }, [_vm._v("check_circle_outline")]) : _c('v-icon', {
            attrs: {
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.activeItem(item, false);
              }
            }
          }, [_vm._v("block")])], 1)]);
        }), 0)];
      }
    }])
  }), _c('v-navigation-drawer', {
    attrs: {
      "app": "",
      "right": ""
    },
    model: {
      value: _vm.filterDrawer,
      callback: function callback($$v) {
        _vm.filterDrawer = $$v;
      },
      expression: "filterDrawer"
    }
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "title"
  }, [_vm._v(" 过滤条件 ")])], 1), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        _vm.filterDrawer = !_vm.filterDrawer;
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('h2', {
    staticClass: "subtitle-2 mt-2"
  }, [_vm._v("是否启用")]), _c('v-chip-group', {
    attrs: {
      "column": "",
      "mandatory": ""
    },
    on: {
      "change": _vm.setFilter
    },
    model: {
      value: _vm.query.isEnable,
      callback: function callback($$v) {
        _vm.$set(_vm.query, "isEnable", $$v);
      },
      expression: "query.isEnable"
    }
  }, _vm._l(_vm.dictEnable, function (enable) {
    return _c('v-chip', {
      key: enable.code,
      attrs: {
        "value": enable.code,
        "filter": "",
        "label": "",
        "outlined": ""
      }
    }, [_vm._v(_vm._s(enable.name))]);
  }), 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "350px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(_vm._s(_vm.dlgTitle))]), _c('v-card-text', [_c('v-container', [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "任务名称",
      "hint": "请输入任务名称",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "name", $$v);
      },
      expression: "editedItem.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "进行中名称",
      "hint": "请输入进行中名称",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.doingName,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "doingName", $$v);
      },
      expression: "editedItem.doingName"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "已完成名称",
      "hint": "请输入已完成名称",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.doneName,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "doneName", $$v);
      },
      expression: "editedItem.doneName"
    }
  }), _c('v-text-field', {
    attrs: {
      "type": "text",
      "label": "任务描述",
      "hint": "请输入任务描述",
      "outlined": ""
    },
    model: {
      value: _vm.editedItem.text,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "text", $$v);
      },
      expression: "editedItem.text"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.employeeList,
      "item-text": "personal.name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "clearable": "",
      "label": "该任务执行人员",
      "hint": "请选择人员"
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        var _data$item, _data$item$employer;
        return [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(data.item.personal.name ? data.item.personal.name : '未设置名称'))]), _c('v-list-item-subtitle', [_vm._v(_vm._s((_data$item = data.item) === null || _data$item === void 0 ? void 0 : (_data$item$employer = _data$item.employer) === null || _data$item$employer === void 0 ? void 0 : _data$item$employer.department.map(function (dept) {
          return dept.name;
        })) + " - " + _vm._s(data.item.phone))])], 1)];
      }
    }]),
    model: {
      value: _vm.editedItem.operator,
      callback: function callback($$v) {
        _vm.$set(_vm.editedItem, "operator", $$v);
      },
      expression: "editedItem.operator"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }