<template>
  <v-container>
    <v-card>
      <v-toolbar flat>
        <v-btn icon @click="goBack()">
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-toolbar-title>流程设置</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-subtitle>
        配置不同业务群组的结算执行环节执行人，重新配置后不会修改原有流程，仅对新的流程启用。
      </v-card-subtitle>
      <v-data-table
        :headers="headerTable"
        :items="taskList"
        :options.sync="optionsTable"
        :server-items-length="taskCount"
        :loading="taskLoading"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [10, 20, 50, 100]}"
        item-key="_id"
        transition
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-tabs icons-and-text fixed-tabs v-model="active_tab">
              <v-tab v-for="item in businessGroup" :key="item.code" @click="changeTab(item.code)">{{item.name}}<v-icon v-text="item.icon"></v-icon></v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-btn depressed color="primary" @click="createItem()"><v-icon left>add</v-icon>新建任务</v-btn>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click.stop="openFilter">
                  <v-icon small>filter_list</v-icon>
                </v-btn>
              </template>
              <span>过滤数据</span>
            </v-tooltip>
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <v-btn text @click="getDataList()">重新获取数据</v-btn>
        </template>
        <template v-slot:body="props">
          <draggable v-model="orderList" v-bind="dragOptions" tag="tbody">
            <tr v-for="(item, index) in props.items" :key="index" :class="item.isEnable ? '' : $vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'">
              <td>
                <v-icon style="cursor: move" class="mt-2" small>drag_indicator</v-icon>
              </td>
              <td>
                {{item.name}}
                <v-icon class="ma-2" color="info" v-if="taskList.indexOf(item) === 0">outlined_flag</v-icon>
                <v-icon class="ma-2" color="info" v-if="taskList.indexOf(item) === taskList.length - 1">flag</v-icon>
              </td>
              <td>
                <account-dialogs v-if="item.operator" :account="item.operator._id">
                  {{item?.operator?.personal?.name || '*未设置姓名'}}
                </account-dialogs>
              </td>
              <td class="text-center">
                <v-icon small class="mr-2" @click="createItem(item)">edit</v-icon>
                <v-icon small @click="activeItem(item, true)" v-if="!item.isEnable">check_circle_outline</v-icon>
                <v-icon small @click="activeItem(item, false)" v-else>block</v-icon>
              </td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
      <v-navigation-drawer v-model="filterDrawer" app right>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">
              过滤条件
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="filterDrawer = !filterDrawer"><v-icon>close</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-card-text>
          <h2 class="subtitle-2 mt-2">是否启用</h2>
          <v-chip-group v-model="query.isEnable" column mandatory @change="setFilter">
            <v-chip v-for="enable in dictEnable" :key="enable.code" :value="enable.code" filter label outlined>{{enable.name}}</v-chip>
          </v-chip-group>
        </v-card-text>
      </v-navigation-drawer>
      <v-dialog v-model="dlgEdit" persistent scrollable max-width="350px">
        <v-form v-model="valid" @submit.prevent="submit">
          <v-card>
            <v-card-title>{{dlgTitle}}</v-card-title>
            <v-card-text>
              <v-container>
                <v-text-field
                  v-model="editedItem.name"
                  :rules="[rules.required]"
                  type="text"
                  label="任务名称"
                  hint="请输入任务名称"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="editedItem.doingName"
                  type="text"
                  label="进行中名称"
                  hint="请输入进行中名称"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="editedItem.doneName"
                  type="text"
                  label="已完成名称"
                  hint="请输入已完成名称"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="editedItem.text"
                  type="text"
                  label="任务描述"
                  hint="请输入任务描述"
                  outlined
                ></v-text-field>
                <v-autocomplete
                  v-model="editedItem.operator"
                  :items="employeeList"
                  item-text="personal.name"
                  item-value="_id"
                  autocomplete="off"
                  outlined
                  clearable
                  label="该任务执行人员"
                  hint="请选择人员"
                >
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title>{{data.item.personal.name ? data.item.personal.name : '未设置名称'}}</v-list-item-title>
                      <v-list-item-subtitle>{{data.item?.employer?.department.map(dept => dept.name)}} - {{data.item.phone}}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="close">取消</v-btn>
              <v-btn color="primary" text @click="submit" :disabled="!valid">保存</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-card>
  </v-container>
</template>
<script>
import draggable from "vuedraggable";
import Util from "@/common/util";
import {mapGetters} from "vuex";
import store from "@/store";
import {
  FETCH_TASK_LIST,
  PUBLISH_TASK,
  EDIT_TASK,
  FETCH_EMPLOYEE_LIST,
  ORDER_TASK_LIST,
  ACTIV_TASK
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          if (value) {
            const pattern = /^1[3456789]\d{9}$/;
            return pattern.test(value) || '请输入正确的电话号码'
          } else {
            return true;
          }
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || '错误的电子邮件地址'
          } else {
            return true;
          }
        },
      },
      valid: true,
      dlgEdit: false,
      dlgTitle: '',
      editedIndex: -1,
      editedItem: {
        _id: '',
        businessGroup: 1,
        name: '',
        doingName: '',
        doneName: '',
        cancelName: '',
        text: '',
        operator: '',
      },
      defaultItem: {
        _id: '',
        businessGroup: 1,
        name: '',
        doingName: '',
        doneName: '',
        cancelName: '',
        text: '',
        operator: '',
      },
      query: {
        key: '',
        group: 1,
        isEnable: true,
      },
      defaultQuery: {
        key: '',
        group: 1,
        isEnable: true,
      },
      headerTable: [
        {text: '排序', width: '85px', value: 'order'},
        {text: '任务名称', width: '225px', value: 'name'},
        {text: '执行人', value: 'operator'},
        {text: '操作', width: '85px', value: 'actions', align: 'end', sortable: false}
      ],
      optionsTable: {},
      active_tab: 0,
      filterDrawer: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_EMPLOYEE_LIST, 'limit=-1&isBlock=false'),
    ]).then(() => {
      next();
    });
  },
  created() {
    this.getDataList()
  },
  computed: {
    ...mapGetters([
      "taskLoading",
      "taskList",
      "taskCount",
      "employeeList"]),
    businessGroup () {
      return Util.categories('businessGroup')
    },
    dictEnable () {
      return Util.categories('dictEnable')
    },
    dragOptions() {
      return {
        animation: 200,
        group: "task",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    orderList: {
      get() {
        return this.$store.state.task.taskList;
      },
      set(list) {
        this.$store.dispatch(ORDER_TASK_LIST, list);
        this.getDataList()
      }
    },
  },
  watch: {
    optionsTable: {
      handler() {
        this.getDataList(this.query)
      },
      deep: true,
    },
    dlgEdit (val) {
      val || this.close()
    },
  },
  mounted() {},
  methods: {
    goBack() {
      this.$router.push({path: '/distributor/system'});
    },
    searchItem() {
      this.optionsTable.page = 1
      this.getDataList()
    },
    clearSearchItem() {
      this.optionsTable.page = 1
      this.query.key = ''
      this.getDataList()
    },
    openFilter() {
      this.filterDrawer = !this.filterDrawer
    },
    setFilter() {
      this.$router.push({ query: {} });
      this.getDataList(this.query)
    },
    changeTab(group) {
      this.query.group = group
      this.getDataList()
    },
    createItem(item) {
      this.editedIndex = this.taskList.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dlgTitle = item ? '编辑任务' : '新建任务'
      this.dlgEdit = true
    },
    activeItem(item, value) {
      if (confirm(`确定要 ${item ? '启用' : '禁用'} ${item.name}？`)) {
        store.dispatch(ACTIV_TASK, {_id: item._id, reply: value})
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
    getDataList() {
      // 获取分页信息
      const {sortBy, sortDesc, page, itemsPerPage} = this.optionsTable;
      let sort = '';
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = '-' + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }
      // 获取参数信息
      if (Object.keys(this.$route.query).length > 0) {
        this.query = Object.assign(this.query, this.$route.query);
      }
      let urlQuery = '';
      if (this.query.key) urlQuery += '&key=' + encodeURIComponent(this.query.key)
      if (this.query.group) urlQuery += '&group=' + this.query.group
      if (this.query.isEnable !== null && this.query.isEnable !== undefined) urlQuery += '&isEnable='+ this.query.isEnable
      if (Object.keys(this.optionsTable).length > 0) {
        store.dispatch(FETCH_TASK_LIST, 'page=' + page + '&limit=' + itemsPerPage + '&sort=' + sort + urlQuery)
      }
    },
    close () {
      this.dlgTitle = ''
      this.dlgEdit = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    submit() {
      let strInsert = {}
      strInsert.businessGroup = this.query.group
      strInsert.name = this.editedItem.name
      strInsert.doingName = this.editedItem.doingName
      strInsert.doneName = this.editedItem.doneName
      strInsert.cancelName = this.editedItem.cancelName
      strInsert.text = this.editedItem.text
      strInsert.operator = this.editedItem.operator

      if (this.editedIndex !== -1) {
        strInsert._id = this.editedItem._id
        store.dispatch(EDIT_TASK, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        store.dispatch(PUBLISH_TASK, strInsert)
          .then(() => {
            store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.getDataList()
            this.close()
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
  }
}
</script>
